import React from 'react'
import Layout from '../../components/Layout'
import HeaderMeta from '../../components/HeaderMeta';

import {
  ResponsiveMaxWidthFrame,
  SectionGroup,
  PageNameBox,
  TitleBox,
  BodyBox,
  LinkBox,
} from "@minware/ui-minware";

const header = {
  title: `Thank you for contacting minware`,
  description: `Thank you for contacting minware to set up a demo, we will reply to you shortly.`,
  canonicalUrl: '/contact/thanks-demo',
}

export default () => (
  <Layout desktopBgImageName="none">
    <HeaderMeta header={header}/>
    <ResponsiveMaxWidthFrame maxWidth="500px">
        <SectionGroup>
          <PageNameBox>Demo Confirmation</PageNameBox>
          <TitleBox>
            Thanks! We will contact you to set up a demo shortly.
          </TitleBox>
        </SectionGroup>
    </ResponsiveMaxWidthFrame>
  </Layout>
)
